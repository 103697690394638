<template>
    <div class="box">
        <p class="el-icon-arrow-left canHand" :class="{disabled:currentPage==1 ||pageTotal==1}" @click="prev"></p>
        <span class="span_num">{{ currentPage }}/{{ pageTotal }}</span>
        <p class="el-icon-arrow-right canHand" :class="{disabled:currentPage==pageTotal}" @click="next"></p>
    </div>
</template>
<script>
export default {
    props:{
        total:{
            type:[Number],
            default:0
        },
        'page-size':{
            type:[Number],
            default:20
        },
        'current-page':{
            type:[Number],
            default:1
        }
    },
    data(){
        return {

        }
    },
    computed: {
        pageTotal:function(){
            return Math.ceil(this.total/this.pageSize)
        },
    },
    methods:{
        prev(){
            if(this.currentPage==1){
                return
            }else{
                let num=this.currentPage
                num--
                this.$emit('page',num);
            }
        },
        next(){
            console.log(this.currentPage,this.pageTotal);
            if(this.currentPage==this.pageTotal){
                return
            }else{
                let num=this.currentPage
                num++
                this.$emit('page',num);
            }
        }
    }

}
</script>
<style lang="less" scoped>
    .box{
        min-width: 114px;
        height: 30px;
        display: flex;
        align-items: center;
        p{
            width: 30px;
            height: 30px;
            display: inline-block;
            text-align: center;
            line-height: 30px;
            border-radius:15px;
        }
    }
    .span_num{
        min-width: 20px;
        margin: 0 15px;
        color: #666;
        font-size: 14px;
        letter-spacing: 2px;
        line-height: 30px;
        text-align: center;
    }
    .canHand:hover{
        background: #f8f8f8;
        color: #ff5c38;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .disabled {
        color: #ccc;
        pointer-events: none;
    }
    
</style>