<template>
  <div :class="{ vItem1: isIndex }" class="vItem">
    <div class="imgbox">
      <el-image
        fit="fit"
        :src="item.cover_url"
        alt=""
        style="width: 100%; height: 100%"
      ></el-image>
      <div class="mark" @click="handleClick">
        <i
          class="el-icon-video-play cursor"
          v-if="item.ctype == 2"
          @click="handleClick"
        ></i>
      </div>
    </div>
    <div class="tit" @click="handleClick" :title="item.name | textFormat">
      {{ item.name | textFormat }}
    </div>
    <div class="footer">
      <div class="price p_top" v-if="item.is_free">免费</div>
      <div class="newPriceBox" v-else>
        <div class="p_flex">
          <small class="price1">￥{{ item.price_actual / 100 }}</small>
          <img :src="img" alt="">
        </div>

        <div class="p_flex2">
          <div class="" >
            <small class="price_label">非会员价：</small>
            <small class="price2">￥{{ item.price_scribe / 100 }}</small>
          </div>
          <div v-if="!item.is_free" >{{ item.num_fbuy }}人购买</div>
        </div>
      </div>
        <!-- {{ item.is_free ? "" : "￥" + item.price_actual / 100 }} -->
      
      
      <!-- <div @click="handleClick">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_eye.png" alt="">
        <span>{{ item.play_count }}</span>
      </div>
      <div @click="handleClick">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_msg.png" alt="">
        <span>{{ item.comment_count }}</span>
      </div>
      <div @click="handleClick">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/icon_heart.png" alt="">
        <span>{{ item.like_count }}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  // props: ["item"],
  props: {
    item: {},
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      img:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/icon_hy.png',
    };
  },
  methods: {
    handleClick() {
      if (this.IS_LOGIN) {
        let routeUrl = this.$router.resolve({
          path: "/college_detail",
          query: {
            parameter: this._encode({
              video_id: this.item.id,
            }),
          },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            let routeUrl = this.$router.resolve({
              path: "/college_detail",
              query: {
                parameter: this._encode({
                  video_id: scope.id,
                }),
              },
            });
            window.open(routeUrl.href, "_blank");
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>

.vItem {
  width: 224px;
  // height: 220px;
  background: #ffffff;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 2px 6px 0 rgba(178, 178, 178, 0.5);
  }
  .imgbox {
    width: 100%;
    height: 126px;
    line-height: 126px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
  }

  .tit {
    // width: 196px;
    height: 42px;
    margin: 9px 18px 0px 12px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    //word-break:break-all;
    //word-wrap: break-word;
  }

  .footer {
    display: flex;
    // justify-content: space-between;
    padding: 0 12px 14px 12px;
    align-items: center;
    justify-content: space-between;
    .p_top{
      padding-top:15px;
    }
    .price {
      color: #ff5500;
      margin-right: 12px;
    }
    .newPriceBox{
      width: 100%;
      border: 1px red;
      .p_flex{
        display: flex;
        align-items: center;
      }
      .p_flex2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .vip{

      }
    }
    .price1{
      font-size: 18px;
      font-weight: 400;
      color: #F13C3C;
      padding: 0;
      display: inline-block;
      padding-right:8px;
    }
    .price2{
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      padding: 0;
    }
    .price_label{
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      padding: 0;
    }

    div {
      cursor: pointer;
      color: #999;
      i {
        font-size: 12px;
        font-weight: 400;
        color: #c0c4cc;
      }
      span {
        display: inline-block;
        padding-left: 6px;
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #c0c4cc;
        line-height: 17px;
      }
    }
  }
  .mark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 126px;
    line-height: 135px;
    text-align: center;
    cursor: pointer;
    .el-icon-video-play {
      font-size: 30px;
      color: #aaa;
    }
  }
}
.vItem1 {
  width: 280px;
  // height: 220px !important;
  .imgbox {
    height: 166px;
  }
  .mark {
    height: 166px;
    line-height: 175px;
  }
}

.mark:hover {
  background: rgba(0, 0, 0, 0.6);
}

.mark:hover .el-icon-video-play {
  color: #fff;
}
</style>