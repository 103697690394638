<template>
  <div style="background-color: #fff">
    <carouselBanner paddingTop="14.6%" :bannerType="5"></carouselBanner>
    <div class="list">
      <div class="wrap content_area">
        <div class="search">
          <div class="title">线上课程</div>
          <div class="input-wrap">
            <el-input
              placeholder="请输入课程名称"
              v-model="searchName"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div class="sortContent">
          <div class="sort clearfix">
            <div class="assort fl">课程分类：</div>
            <div class="item fl">
              <div class="clearfix">
                <div
                  class="fl its"
                  :class="[active == item.id ? 'active' : '']"
                  v-for="(item, index) in type"
                  :key="index"
                  v-if="item.is_show"
                  @click="changeItem(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix item sortItem" v-show="activeType.length">
            <div
              class="fl its"
              :class="[activeItem == item.id ? 'active' : '']"
              v-for="(item, index) in activeType"
              :key="index"
              v-if="item.is_show"
              @click="changeActive(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="content" v-loading="loading">
          <videoItem
            class="item-wrap"
            v-for="(item, index) in videoList"
            :key="index"
            :item="item"
          />
          <noDataImg v-if="videoList.length === 0"></noDataImg>
        </div>
        <div class="pagination" v-if="total > limit">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="limit"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import videoItem from "~scb/components/college/videoItem";
import Pagination from "@/baseComponents/Pagination";

export default {
  metaInfo: {
    title: "线上课程",
  },
  components: {
    carouselBanner,
    videoItem,
    Pagination,
  },
  data() {
    return {
      imgList: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_1.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_2.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/big_pic_3.png",
      ],
      videoList: [],
      activeType: [],
      currentPage: 1,
      limit: 25,
      total: 0,
      loading: false,
      type: [],
      active: 0,
      activeItem: 0,
      searchName: "",
    };
  },
  watch: {
    currentPage() {
      this.getList();
    },
  },
  mounted() {
    this.getList();
    this.getTypeList();
  },
  methods: {
    search() {
      this.currentPage = 1;
      this.getList();
    },
    // 获取视频列表
    async getList(val) {
      this.loading = true;
      let params = {
        audit_status: 2,
        status: 1,
      };
      params.source = this.PJSource;
      params.start = this.currentPage - 1;
      params.limit = this.limit;
      params.nopage = 0;
      if (this.searchName) {
        params.name = this.searchName;
      }
      if (val) params.category = val;
      let list = await this.$store.dispatch(
        "API_company/getEduVideoList",
        params
      );
      this.videoList = list.data;
      this.total = list.count;
      this.loading = false;
    },
    async getTypeList() {
      let params = {
        format: 1,
      };
      params.source = this.PJSource;
      params.nopage = 1;
      let res = await this.$store.dispatch(
        "API_company/getVideoTypeList",
        params
      );
      if (res.success) {
        this.type = [
          {
            name: "全部",
            id: 0,
            is_show: 1,
          },
        ];
        this.type.push(...res.data.children);
      }
    },
    changeItem(item) {
      this.active = item.id;
      this.currentPage = 1;
      this.activeItem = 0;
      if (!item.children || item.children.length == 0) {
        this.getList(this.active);
        this.activeType = [];
      } else {
        this.activeType = item.children;
        this.getList(this.active);
      }
    },
    changeActive(item) {
      this.activeItem = item.id;
      this.getList(this.activeItem);
    },
  },
};
</script>
<style lang="less" scoped>
.list {
  padding: 20px 0 50px;
  background-color: #fafafa;
}

.wrap {
  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;

    .title {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
    }

    .input-wrap {
      width: 352px;

      /deep/ .el-input-group__append {
        background-color: #e3944d;
        color: #fff;
      }
    }
  }
  .sortContent {
    width: 100%;
    min-height: 64px;
    margin-bottom: 20px;
    .sortItem {
      background: #fff;
      width: 100%;
    }
    .item{
      width: 1050px;
    }
    .item .its {
      padding: 9px 10px;
      margin-right: 20px;
      color: #000;
      cursor: pointer;
      &.active {
        color: #e3954f;
      }
    }
    .sortItem {
      background: #fff;
      border-top: 1px solid #dddddd;
      padding: 10px 20px 10px 135px;
    }
    .sort {
      padding: 10px 20px;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .assort {
        padding-top: 9px;
        width: 100px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    min-height: 234px;
    position: relative;
    .item-wrap {
      margin-right: 20px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .pagination {
    /deep/ .el-pagination {
      text-align: right;
    }
  }
}

.myConten {
  width: 1200px;
  margin: auto;
}

.xiubox {
  .tit {
    height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: #192f59;
    line-height: 33px;
  }

  .scrollBox {
    display: flex;
    flex-wrap: nowrap;
    height: 300px;
    width: 1200px;

    img {
      width: 220px;
      height: 325px;
      border-radius: 10px;
    }
  }
}

.video {
  .videoNav {
    padding-top: 36px;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    .search {
      h1 {
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #e3954f;
        line-height: 33px;
      }
    }
  }

  .videoBox {
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;

    .nodataBox {
      height: 400px;
      width: 200px;
      margin: auto;
      position: relative;
      text-align: center;
    }
  }

  .isRight {
    margin-right: 25px;
  }
}
</style>
